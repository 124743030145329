import React from "react";

export default function CompanyGoal() {
  return (
    <div className="bg-seas-dark flex flex-col items-center">
      <h1 id="idea" className="text-3xl font-semibold text-center mt-8 pt-16">
        Unternehmensziel
      </h1>
      <div className="flex flex-col md:flex-row items-center justify-center mt-6 gap-6 pb-20 max-w-4xl w-[88vw]">
        <p className="text-3xl">&#128154;</p>
        <div className=" text-xl">
          <p>
            Erklärtes Unternehmensziel ist nicht die Gewinnmaximierung, sondern
            das Gegenteil: Mögliche Überschüsse werden an karitative
            Organisationen gespendet oder sie werden mildtätigen Zwecken
            zugeführt. Dazu verzichtet der geschäftsführende Gesellschafter
            selbstverständlich auf Gehalt.
          </p>
          <p className="mt-2">
            Ein weiteres Unternehmensziel ist, dass SEAS sich als Standardlösung
            für die Erstellung von Plänen in Kirchengemeinden durchsetzt und
            sich damit der Administrationsaufwand für die beteiligten Personen
            deutlich reduziert.
          </p>
        </div>
      </div>
    </div>
  );
}
