import React from "react";

export default function DesignRequirements() {
  return (
    <div className="w-full bg-seas-light pb-12">
      <h2 className="mx-8 text-center text-3xl font-semibold pt-12">
        Unsere Designkriterien
      </h2>
      <div className="flex flex-col md:flex-row justify-center mt-12">
        <div className="flex w-full md:w-[20vw] justify-center md:justify-end">
          <p>per Klick</p>
        </div>

        <div className="w-[88vw] md:w-[50vw] md:max-w-lg flex justify-center md:justify-start h-fit">
          <div className="divider divider-horizontal py-2"></div>
          <div className="text-xl flex flex-col gap-2 font-semibold">
            <h3>Intuitives Handling</h3>
            <h3>Easy Setup</h3>
            <h3>Paradigmenwechsel</h3>
            <h3>
              Offene Schnittstellen{" "}
              <span className="text-seas-green">&#x2713;</span>
            </h3>
            <h3>
              Beliebig skalierbar{" "}
              <span className="text-seas-green">&#x2713;</span>
            </h3>
            <h3>
              DSGVO-konform <span className="text-seas-green">&#x2713;</span>
            </h3>
          </div>
        </div>
        <div className="hidden md:block md:w-[20vw]">
          <img
            src="/img/seas-screenshot-settings.png"
            alt="seas-screenshot-settings"
            className="w-52"
          />
        </div>
      </div>
    </div>
  );
}
