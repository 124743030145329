import React from "react";

export default function PlanMindmap() {
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-[auto_min-content_auto] bg-seas-accent shadow-lg pt-4 px-4">
        <div className="hidden sm:flex"></div>
        <div className="flex flex-col items-center">
          <div className="w-48 text-black">
            <p className="font-semibold text-lg">Musik</p>
            <p>Dirigent, Orgel, Orchester, etc.</p>
          </div>
          <img
            src="/img/mindmap-line-top.svg"
            alt="mindmap-line-top"
            className="w-10 lg:w-20 mt-[-1rem]"
          />
        </div>
        <div className="hidden sm:flex"></div>
        <div className="hidden sm:flex items-center justify-end">
          <div className="w-48 hidden sm:block text-black">
            <p className="font-semibold text-lg">Unterrichte</p>
            <p>
              Sonntagsschule, Vorsonntagsschule, Konfirmantenunterricht, etc.
            </p>
          </div>
          <img
            src="/img/mindmap-line-left.svg"
            alt="mindmap-line-left"
            className="h-0 md:h-10 lg:h-20"
          />
        </div>
        <div className="flex justify-center items-center">
          <div className="border-2 rounded-lg border-black/60 flex flex-col items-center px-4 py-2 shadow-lg bg-gray-200">
            <h2 className="text-3xl font-semibold text-black/90">
              Gemeindepläne
            </h2>
            <p className="text-black/100">individuell konfigurierbar</p>
          </div>
        </div>
        <div className="hidden sm:flex items-center">
          <img
            src="/img/mindmap-line-right.svg"
            alt="mindmap-line-right"
            className="h-0 md:h-10 lg:h-20"
          />
          <div>
            <p className="font-semibold text-lg hidden sm:block text-black">
              Diakonischer Dienst
            </p>
            <p className="text-black">zeitlich und/oder ortsbezogen</p>
          </div>
        </div>
        <div className="hidden sm:flex"></div>
        <div className="flex flex-col items-center">
          <img
            src="/img/mindmap-line-bottom.svg"
            alt="mindmap-line-bottom"
            className="w-10 lg:w-20 sm:mt-[-1rem]"
          />
          <div className="mb-6 text-black">
            <p className="font-semibold text-lg">Sonstige Dienste</p>
            <p>Blumen, Reinigung, etc.</p>
          </div>
        </div>
        <div className="hidden sm:flex"></div>
      </div>
    </>
  );
}
