import React from "react";

export default function OurBeginning() {
  return (
    <>
      <div className="mb-12 flex flex-col items-center">
        <h1 id="idea" className="text-3xl font-semibold text-center">
          Unser Anfang
        </h1>
      </div>

      <div className="flex flex-col md:flex-row justify-center items-center">
        <div className="px-4 flex flex-col gap-2 md:gap-12 max-w-lg">
          <div>
            <h3 className="text-sm px-2">1. Umsetzung für die Musik</h3>
            <p className="bg-seas-medium rounded-xl py-1 px-2 text-lg dark:bg-base-200">
              Relativ zügig haben wir ein Team gebildet, die Idee ausgearbeitet
              und den Beteiligten zur Beurteilung vorgestellt. Nach Absprache
              einiger Regeln, wie z.B., dass sich Mütter mit Kindern oder
              Schichtarbeiter als Erste eintragen, haben wir das System genutzt.
              Und es hat sehr gut funktioniert!
            </p>
          </div>
          <div className="flex justify-center md:hidden">
            <img
              src="/img/beginning-line-1.svg"
              alt="beginning-line-1"
              className="h-56 -mt-4 -mb-16 -mr-[15vw]"
            />
          </div>

          <div>
            <h3 className="text-sm px-2 md:hidden">2. Diakonischer Dienst</h3>
            <h3 className="text-sm px-2 hidden md:block">
              Ausarbeitung zu einem vollwertigen Produkt
            </h3>
            <p className="bg-seas-medium rounded-xl py-1 px-2 text-lg dark:bg-base-200 md:hidden">
              Als die Diakone die Möglichkeiten des Systems mitbekamen, wollten
              sie es ebenfalls benutzen. Gesagt, getan, kein Problem! Das
              Ergebnis war die volle Zufriedenheit mit unserer Innovation.
            </p>
            <p className="bg-seas-medium rounded-xl py-1 px-2 text-lg dark:bg-base-200 hidden md:block">
              Nachdem weitere Gemeinden Interesse an der Lösung bekundeten,
              haben wir alle erforderlichen Entscheidungen getroffen und die
              nötigen Maßnahmen durchgeführt, um die Lösung als Standardsoftware
              zu entwickeln und auf den Markt zu bringen.
            </p>
          </div>
        </div>
        <div className="my-2 w-full flex justify-center md:hidden">
          <img
            src="/img/beginning-line-2.svg"
            alt="beginning-line-1"
            className="h-44 -my-4 -ml-[15vw]"
          />
        </div>
        <div className="my-2 hidden md:flex flex-col -mx-6 lg:-mx-8 gap-4">
          <img
            src="/img/beginning-line-3.svg"
            alt="beginning-line-3"
            className="h-36"
          />
          <img
            src="/img/beginning-line-4.svg"
            alt="beginning-line-4"
            className="h-36"
          />
        </div>
        <div className="flex items-center max-w-lg">
          <div className="mx-4">
            <h3 className="text-sm px-2 md:hidden">
              Ausarbeitung zu einem vollwertigen Produkt
            </h3>
            <h3 className="text-sm px-2 hidden md:block">
              2. Diakonischer Dienst
            </h3>
            <p className="bg-seas-medium rounded-xl py-1 px-2 text-lg dark:bg-base-200 md:hidden">
              Nachdem weitere Gemeinden Interesse an der Lösung bekundeten,
              haben wir alle erforderlichen Entscheidungen getroffen und die
              nötigen Maßnahmen durchgeführt, um die Lösung als Standardsoftware
              zu entwickeln und auf den Markt zu bringen.
            </p>
            <p className="bg-seas-medium rounded-xl py-1 px-2 text-lg dark:bg-base-200 hidden md:block">
              Als die Diakone die Möglichkeiten des Systems mitbekamen, wollten
              sie es ebenfalls benutzen. Gesagt, getan, kein Problem! Das
              Ergebnis war die volle Zufriedenheit mit unserer Innovation.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
