import React from "react";

export default function DummyHeader() {
  return (
    <div className="h-16 shadow-lg flex items-center justify-between w-full px-2 bg-seas-dark">
      <p className="text-lg font-semibold">Ihre Testgemeinde</p>
      <img
        src="/img/dummy-menu.svg"
        alt="dummy-menu"
        className="h-4 w-4 cursor-pointer"
      />
    </div>
  );
}
