import React from "react";

import DummyHeader from "../elements/dummy/DummyHeader";
import DummyQuarterSelector from "../elements/dummy/DummyQuarterSelector";
import DummyBoard from "./DummyBoard";

export default function Dummy() {
  return (
    <div className="bg-seas-light">
      <DummyHeader />
      <div className="p-4">
        <div className="w-full shadow-xl pb-4">
          <DummyQuarterSelector />
          <div className="bg-seas-accent-dark text-white rounded-full text-lg w-fit px-1 mx-6 cursor-pointer">
            Musikplan
          </div>
          <div className="divider p-0 !m-0 !mx-6"></div>
          <DummyBoard />
        </div>
      </div>
    </div>
  );
}
