import React from "react";

import SectionWrapper from "../components/SectionWrapper";

export default function AutomaticEvents() {
  return (
    <SectionWrapper>
      <div className="flex md:w-2/3 bg-seas-medium items-center flex-col">
        <div className="mx-4">
          <h2 className="text-4xl font-semibold mt-12 lg:mt-48">
            <span className="font-bold text-seas-green">Automatisch</span>{" "}
            Termine erstellen
          </h2>
          <p>
            Nie wieder manuell einzelne Dienstpläne erstellen, ausdrucken,
            aufhängen ...
          </p>

          <h3 className="text-lg mt-2">Automatische Erzeugung von Terminen</h3>
          <p className="ml-2">
            <span className="font-semibold">Wöchentlich:</span> z.B. jede Woche
            Sonntag oder Mittwoch/Donnerstag
          </p>
          <p className="ml-2">
            <span className="font-semibold">Monatlich:</span> z.B. Jeden Monat
            am 1. Tag
          </p>
          <p className="ml-2">
            <span className="font-semibold">Jährlich:</span> z.B. Jedes Jahr an
            Weihnachten
          </p>

          <p className="mt-2">
            Zusätzlich können auch individuelle Termine eingetragen werden, z.B.
            Hochzeiten, Karfreitag.
          </p>
        </div>
      </div>
      <div className="flex md:w-1/3 bg-seas-medium justify-center items-center p-12">
        <img
          src="/img/seas-screenshot-event.png"
          alt="seas-screenshot-event"
          className="w-64 rounded-lg"
        />
      </div>
    </SectionWrapper>
  );
}
