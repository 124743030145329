import React from "react";
import { Route, Routes } from "react-router-dom";

import App from "./App";
import Conditions from "./Conditions";
import Imprint from "./Imprint";
import Privacy from "./Privacy";

export const routes = (
  <Routes>
    <Route path="/*" element={<App />} />
    <Route path="/imprint" element={<Imprint />} />
    <Route path="/conditions" element={<Conditions />} />
    <Route path="/privacy" element={<Privacy />} />
  </Routes>
);
