export default function Header() {
  return (
    <header className="flex flex-wrap items-center justify-between border-b border-black/20 shadow-md md:px-5 lg:px-16 md:py-2 bg-seas-light">
      <div className="flex h-20 items-center">
        <a href="/" className="h-full">
          <img src="/favicon-194x194.png" alt="Logo" className="h-full" />
        </a>

        <a href="/" className="ml-8">
          <p className="text-lg font-semibold text-seas-text">SEAS</p>
          <p className="text-seas-text">Kirchengemeinde</p>
        </a>
      </div>
      <div className="flex items-center gap-4 ml-2 my-2 pr-2 md:pr-0">
        <a
          href="/#idea"
          className="text-lg underline text-seas-text font-semibold"
        >
          Die Idee
        </a>
        <a href="/#start">
          <button className="btn btn-success shadow-md">
            Kostenlos starten
          </button>
        </a>
      </div>
    </header>
  );
}
