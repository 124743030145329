import React from "react";

export default function ProductionTest() {
  return (
    <div className="flex flex-col items-center pb-8" id="start">
      <h1 id="idea" className="text-3xl font-semibold text-center mt-8">
        &#127937; Praxiserprobung &#127937;
      </h1>
      <div className="w-[90vw] md:w-1/2 border-2 border-black/60 rounded-lg px-6 py-4 mt-6">
        <p className="text-2xl">
          Die ersten 50 Gemeinden erhalten die Software dazu unbegrenzt{" "}
          <span className="font-semibold">KOSTENLOS</span>
        </p>
      </div>

      <h2 className="mt-12">Bestellung an:</h2>
      <a
        href="mailto:info@seas-kirchengemeinde.de"
        className="text-4xl bg-seas-dark rounded-lg px-4 py-2"
      >
        info@seas-kirchengemeinde.de
      </a>
      <p className="text-xs">(Mit Angabe des Gemeindenamens)</p>
    </div>
  );
}
