import React from "react";

import SectionWrapper from "../components/SectionWrapper";

export default function EasyPrint() {
  return (
    <SectionWrapper>
      <div className="flex md:w-2/3 bg-seas-dark items-center flex-col">
        <div className="mx-4">
          <h2 className="text-4xl font-semibold mt-12 lg:mt-48">
            <span className="font-bold text-seas-green">Einfach</span>{" "}
            ausdrucken
          </h2>
          <p>
            Alle Ausdrucke mit minimalem Aufwand intuitiv konfigurierbar, in 3
            Schritten:
          </p>

          <p className="ml-2 mt-2">
            <span className="font-semibold">1.</span> Zeitraum auswählen
          </p>
          <p className="ml-2 mt-1">
            <span className="font-semibold">2.</span> Spalten auswählen
          </p>
          <p className="ml-2 mt-1">
            <span className="font-semibold">3. </span> In gewünschter
            Reihenfolge drucken
          </p>
        </div>
      </div>
      <div className="flex md:w-1/3 bg-seas-dark justify-center items-center p-12">
        <img
          src="/img/seas-screenshot-print.png"
          alt="seas-screenshot-print"
          className="w-64 rounded-lg"
        />
      </div>
    </SectionWrapper>
  );
}
