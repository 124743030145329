import React from "react";

import SectionWrapper from "../components/SectionWrapper";

export default function DirectCalendar() {
  return (
    <SectionWrapper>
      <div className="flex md:w-2/3 bg-seas-medium items-center flex-col">
        <div className="mx-4">
          <h2 className="text-4xl font-semibold mt-12 lg:mt-48">
            <span className="font-bold text-seas-green">Direkter</span>{" "}
            Kalenderexport
          </h2>
          <p>Einfache Übernahme aller Termine in den lokalen Kalender</p>

          <ul className="pl-4 list-disc">
            <li>Direkter Export in den Handykalender (Nur in der App)</li>
            <li>Export als Kalenderdatei (.ics)</li>
          </ul>
        </div>
      </div>
      <div className="flex md:w-1/3 bg-seas-medium justify-center items-center p-12">
        <img
          src="/img/seas-screenshot-calendar.png"
          alt="seas-screenshot-calendar"
          className="w-64 rounded-lg"
        />
      </div>
    </SectionWrapper>
  );
}
