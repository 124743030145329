export default function ScreenshotWrapper(props) {
  return (
    <div>
      <p className="w-60 md:w-40 lg:w-52 p-2 text-center font-semibold">
        {props.text}
      </p>
      <img
        src={props.img}
        alt={props.alt}
        className="w-60 md:w-40 lg:w-52 rounded-lg"
      />
    </div>
  );
}
