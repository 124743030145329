import { useState } from "react";

import Dummy from "../components/Dummy";
import ScreenshotWrapper from "../components/ScreenshotWrapper";
import SectionWrapper from "../components/SectionWrapper";

export default function Section1() {
  const [dummyLoaded, setDummyLoaded] = useState(false);

  return (
    <>
      <SectionWrapper>
        <div className="flex flex-grow flex-col items-center justify-center md:w-1/2 bg-seas-dark min-h-[95vh] md:shadow-lg pt-12">
          <h1 className="px-4 text-center text-6xl font-semibold md:hidden">
            <span className="font-bold text-seas-green">SEAS</span>
          </h1>
          <small className="md:hidden">
            Selbsteinteilungs- & Administrationssoftware
          </small>
          <div className="mb-12 mt-4 flex flex-wrap items-center justify-center gap-2 md:gap-4 px-4">
            <ScreenshotWrapper
              text="1-Klick Einteilung in frei definierbare Spalten"
              img="/img/seas-screenshot-1.png"
              alt="Screenshot 1"
            />
            <ScreenshotWrapper
              text="Die ganze Gemeinde auf einen Blick"
              img="/img/seas-screenshot-2.png"
              alt="Screenshot 2"
            />
            <ScreenshotWrapper
              text="Mehrere Pläne - geteilte Spalten"
              img="/img/seas-screenshot-3.png"
              alt="Screenshot 3"
            />
            <ScreenshotWrapper
              text="Volle Kontrolle - kaum Aufwand"
              img="/img/seas-screenshot-4.png"
              alt="Screenshot 4"
            />
          </div>
        </div>
        <div className="md:w-1/2 shadow-lg bg-gradient-to-br from-white dark:from-base-100 to-black/50 dark:to-base-300">
          <div className="hidden md:flex flex-col h-[calc(100vh-5rem)] justify-center">
            <h1 className="px-4 text-center text-6xl font-semibold">
              <span className="font-bold text-seas-green">SEAS</span>
            </h1>
            <small className="text-center">
              Selbsteinteilungs- & Administrationssoftware
            </small>
          </div>

          <div className="my-2 w-full flex justify-center md:hidden">
            <div className="mockup-window border border-base-300 w-full mx-8 md:w-2/3 lg:w-[32rem] h-fit my-8 md:my-0 bg-seas-light shadow-lg">
              {!dummyLoaded && (
                <div className="border-t border-base-300 flex items-center flex-col">
                  <h2 className="text-2xl font-semibold mt-8 text-center mx-2">
                    Dummy Anwendung starten?
                  </h2>
                  <div className="flex justify-center mt-2 mb-8">
                    <button
                      className="btn btn-outline"
                      onClick={() => setDummyLoaded(true)}
                    >
                      Jetzt laden
                    </button>
                  </div>
                </div>
              )}
              {dummyLoaded && (
                <div className="border-t border-base-300">
                  <Dummy />
                </div>
              )}
            </div>
          </div>
        </div>
      </SectionWrapper>
      <div className="bg-seas-light hidden md:block p-8">
        <div className="mockup-window border border-base-300 w-full max-w-2xl h-fit bg-seas-light shadow-lg mx-auto">
          {!dummyLoaded && (
            <div className="border-t border-base-300 flex items-center flex-col">
              <h2 className="text-2xl font-semibold mt-8 text-center mx-2">
                Dummy Anwendung starten?
              </h2>
              <div className="flex justify-center mt-2 mb-8">
                <button
                  className="btn btn-outline"
                  onClick={() => setDummyLoaded(true)}
                >
                  Jetzt laden
                </button>
              </div>
            </div>
          )}
          {dummyLoaded && (
            <div className="border-t border-base-300">
              <Dummy />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
