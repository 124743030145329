import React from "react";

import DummyBoardRow from "../elements/dummy/DummyBoardRow";

export default function DummyBoard() {
  return (
    <div className="mx-6 shadow-lg">
      <div className="flex flex-row w-full bg-seas-medium rounded-t-lg">
        <div className="w-1/4">
          <p className="font-semibold text-sm lg:text-base uppercase py-4 lg:px-1">
            Termin
          </p>
        </div>
        <div className="w-1/4">
          <p className="font-semibold text-sm lg:text-base uppercase py-4 lg:px-1">
            Dirigent
          </p>
        </div>
        <div className="w-1/4">
          <p className="font-semibold text-sm lg:text-base uppercase py-4 lg:px-1">
            Orgel
          </p>
        </div>
        <div className="w-1/4">
          <p className="font-semibold text-sm lg:text-base uppercase py-4 lg:px-1">
            Kommentar
          </p>
        </div>
      </div>
      <DummyBoardRow
        date="So, 7.1."
        comment={"Frohes neues!"}
        dirigent={"Peter Lustig"}
      />
      <DummyBoardRow date="So, 14.1." />
      <DummyBoardRow date="So, 21.1." orgel={"Herbert Müller"} />
    </div>
  );
}
