import Footer from "./components/Footer";
import Header from "./components/Header";
import AutomaticEvents from "./sections/AutomaticEvents";
import CompanyGoal from "./sections/CompanyGoal";
import CoreValues from "./sections/CoreValues";
import DesignRequirements from "./sections/DesignRequirements";
import DirectCalendar from "./sections/DirectCalendar";
import EasyPrint from "./sections/EasyPrint";
import HelmutHaase from "./sections/HelmutHaase";
import Idea from "./sections/Idea";
import NowState from "./sections/NowState";
import OurBeginning from "./sections/OurBeginning";
import PlanMindmap from "./sections/PlanMindmap";
import ProductionTest from "./sections/ProductionTest";
import Ratings from "./sections/Ratings";
import Section1 from "./sections/Section1";
import UnderConstruction from "./sections/UnderConstruction";

function App() {
  return (
    <div>
      <Header />
      <Section1 />
      <PlanMindmap />
      <AutomaticEvents />
      <EasyPrint />
      <DirectCalendar />
      <Ratings />
      <CoreValues />
      <DesignRequirements />
      <div className="divider mt-0 pt-0 dark:hidden"></div>
      <Idea />
      <OurBeginning />
      <NowState />
      <ProductionTest />
      <CompanyGoal />
      <HelmutHaase />

      <UnderConstruction />
      <Footer />
    </div>
  );
}

export default App;
