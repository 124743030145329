import React, { useState } from "react";

export default function DummyBoardRow({ date = "", comment, dirigent, orgel }) {
  const [firstName, setFirstName] = useState();
  const [secondName, setSecondName] = useState();

  return (
    <div className="border-t border-gray-300 flex items-center">
      <div className="w-1/4">
        <p className="text-sm lg:text-base py-4 lg:px-1">{date}</p>
      </div>
      <div className="w-1/4">
        {dirigent && (
          <p className="text-sm lg:text-base py-4 lg:px-1">{dirigent}</p>
        )}
        {!dirigent && (
          <>
            {firstName && (
              <p className="text-sm lg:text-base py-4 lg:px-1 font-semibold underline">
                {firstName}
              </p>
            )}
            {firstName === undefined && (
              <div className="py-4 lg:px-1">
                <button
                  className="h-8 w-8 rounded-xl flex items-center justify-center cursor-pointer text-black bg-seas-accent-dark text-lg font-bold"
                  onClick={() => {
                    setFirstName("Ihr Name");
                    setSecondName("");
                  }}
                >
                  +
                </button>
              </div>
            )}
          </>
        )}
      </div>
      <div className="w-1/4">
        {orgel && <p className="text-sm lg:text-base py-4 lg:px-1">{orgel}</p>}
        {!orgel && (
          <>
            {secondName && (
              <p className="text-sm lg:text-base py-4 lg:px-1 font-semibold underline">
                {secondName}
              </p>
            )}
            {secondName === undefined && (
              <div className="py-4 lg:px-1">
                <button
                  className="h-8 w-8 rounded-xl flex items-center justify-center cursor-pointer bg-seas-accent-dark text-lg font-bold text-black"
                  onClick={() => {
                    setFirstName("");
                    setSecondName("Ihr Name");
                  }}
                >
                  +
                </button>
              </div>
            )}
          </>
        )}
      </div>
      <div className="w-1/4">
        <p className="text-sm lg:text-base py-4 lg:px-1">{comment ?? "-"}</p>
      </div>
    </div>
  );
}
