import React from "react";

export default function CoreValues() {
  return (
    <div className="w-full bg-seas-dark flex items-center justify-center">
      <div className="flex flex-col my-[20vh] gap-4 w-[88vw] md:w-[50vw] max-w-xl">
        <h1 className="text-4xl font-bold  text-left">
          <span className="text-seas-green">Dramatische </span>
          Einsparungen.
        </h1>
        <h1 className="text-4xl font-bold text-center">
          <span className="text-seas-green">Volle </span>
          Transparenz.
        </h1>
        <h1 className="text-4xl font-bold text-right">
          <span className="text-seas-green">Minimaler </span>
          Aufwand.
        </h1>
      </div>
    </div>
  );
}
