import React from "react";

export default function NowState() {
  return (
    <>
      <div className="pb-12 flex flex-col items-center mt-8 pt-16 bg-seas-accent">
        <h1 id="idea" className="text-3xl font-semibold text-center text-black">
          IST - Status
        </h1>
      </div>
      <div className="flex items-center justify-center bg-seas-accent pb-20">
        <div className="text-lg flex flex-col gap-3 font-semibold text-black">
          <p>✅ Qualitätsprüfung durchgeführt</p>
          <p>✅ Testphase abgeschlossen</p>
          <p>✅ Produktionsumgebung sichergestellt</p>
          <p>✅ Unternehmensgesellschaft (haftungsbeschränkt) aktiv</p>
        </div>
      </div>
    </>
  );
}
