export default function Section2() {
  return (
    <section className="flex justify-center bg-seas-darkest">
      <div className="flex flex-wrap justify-around gap-4 pb-8 md:w-1/2">
        <Rating
          image={"/img/person2.png"}
          ratings
          text={"So einfach"}
          name={"Rita, 78"}
        />
        <Rating image={"/img/person1.png"} text={"Danke"} />
        <Rating
          image={"/img/person3.png"}
          name={"Herbert"}
          ratings
          text={
            "Ich hätte nicht gedacht, dass es so einfach sein kann, die ganze Gemeinde zu verwalten. Diese App spart mir so viele Stunden, ich bin euch echt dankbar!"
          }
        />
        <Rating image={"/img/person2.png"} text={"Super Tool"} />
        <Rating
          image={"/img/person3.png"}
          text={"Unglaublich, warum nicht schon früher?"}
        />
      </div>
    </section>
  );
}

const Rating = ({ image, ratings = false, text, name }) => {
  return (
    <div className="flex flex-col items-center mt-8">
      {image && (
        <img src={image} alt={image} className="mask mask-circle w-16" />
      )}
      {name && <p className="text-gray-100 font-semibold">{name}</p>}
      {ratings && (
        <div className="rating mt-1">
          <input
            type="radio"
            name="rating-1"
            className="mask mask-star bg-orange-400"
            disabled
          />
          <input
            type="radio"
            name="rating-1"
            className="mask mask-star bg-orange-400"
            disabled
          />
          <input
            type="radio"
            name="rating-1"
            className="mask mask-star bg-orange-400"
            disabled
          />
          <input
            type="radio"
            name="rating-1"
            className="mask mask-star bg-orange-400"
            disabled
          />
          <input
            type="radio"
            name="rating-1"
            className="mask mask-star bg-orange-400"
            disabled
            checked
          />
        </div>
      )}
      <p className="w-32 text-center text-sm text-gray-100">
        &bdquo;{text}&rdquo;
      </p>
    </div>
  );
};
