import React from "react";

export default function DummyQuarterSelector() {
  return (
    <div className="flex flex-row justify-center gap-4 items-center mt-4 flex-wrap">
      <div className="flex items-center gap-1 cursor-pointer">
        <p className="rotate-180 text-3xl mt-[5px]">&#10152;</p>
        <p className="underline">4. Quartal</p>
      </div>
      <div className="border rounded-sm py-1 px-2 flex flex-wrap items-center w-32 bg-seas-dark border-black">
        <p className="w-full text-center">2024</p>
        <p className="font-semibold text-xl w-full text-center">1. Quartal</p>
      </div>
      <div className="flex items-center gap-1 cursor-pointer">
        <p className="underline">2. Quartal</p>
        <p className="text-3xl">&#10152;</p>
      </div>
    </div>
  );
}
