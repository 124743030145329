import React from "react";

export default function Idea() {
  return (
    <>
      <h1 id="idea" className="text-3xl font-semibold text-center mt-8 mb-12">
        Die Idee
      </h1>
      <div className="flex flex-col md:flex-row justify-center items-center">
        <div className="px-4 flex flex-col gap-2 max-w-lg">
          <div className="bg-seas-medium rounded-xl py-1 px-2 text-lg dark:bg-base-200">
            Als Musikverantwortlicher der Gemeinde Wuppertal Ost in NRW, bin ich
            Ende 2023 in den Ruhestand gegangen. <br /> Ca. ein halbes Jahr
            vorher habe ich überlegt, was zu tun ist, um einen reibungslosen
            Übergang sicherzustellen.
          </div>
          <div className="bg-seas-medium rounded-xl py-1 px-2 text-lg dark:bg-base-200">
            Ein Teilaspekt dabei, war die Optimierung der Erstellung der
            Einteilungspläne für die Orgel und das Dirigat. Obwohl wir schon auf
            Quartalspläne umgestellt hatten, war es immer wieder ein erheblicher
            Aufwand.
          </div>
        </div>
        <div className="flex items-center flex-col my-2">
          <p className="text-2xl">⚡</p>
          <p className="text-xs">(Gedankenblitz)</p>
        </div>
        <div className="flex items-center max-w-lg">
          <div className="mx-4 bg-seas-medium rounded-xl py-1 px-2 text-lg dark:bg-base-200">
            Irgendwann habe ich mir dann die Frage gestellt, ob der
            Verantwortliche diese Pläne wirklich noch selbst zu erstellen hat,
            oder ob andere Verfahren/Technologien zur deutlichen Verringerung
            des Aufwandes zur Verfügung stehen. <br /> Zugegeben, die Idee zur
            Selbsteinteilung der Beteiligten hielt ich am Anfang schon für etwas
            verwegen, aber auch genial. Deshalb hat mich wohl die Umsetzung
            gereizt.
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-6">
        <img
          src="/img/idea-line-bottom.svg"
          alt="idea-line-bottom"
          className="h-64"
        />
      </div>
    </>
  );
}
